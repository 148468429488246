@import './reset';
@import './variables';
@import './fonts';
@import './font-awesome';
@import './mixins';
@import './buttons';
@import './forms';
@import 'react-toastify/dist/ReactToastify.css';

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background: none;
  padding-left: 0.75rem;
}

.invalid-feedback {
  font-size: 0.75rem;
}

.form-label {
  color: var(--bs-gray-700);
  font-size: 0.875rem;
}

.general-shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 14px;
}

p,
span {
  color: $text-color;
}

.Toastify__toast-body {
  div {
    font-family: 'IRANSansWeb';
  }
}

.upload-file-custome {
  width: 275px;
  height: 275px !important;
  border-radius: 50% !important;

  .overlay-control {
    border-radius: 50% !important;
  }
}

.info-alert {
  font-size: 0.875rem;

  h6 {
    font-weight: bold;
  }

  i {
    font-size: 30px;
    font-weight: bold;
  }

  .triangle {
    padding: 10px;
    border-left: 1px solid #cfe2ff;
    align-self: center;
  }
}

.custom-modal-body {
  width: 100%;
  max-height: calc(100vh - 180px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  margin-left: -8px;
  padding-left: 8px;

  @media only screen and (max-width: 768px) {
    margin-left: -4px;
    padding-left: 4px;
  }

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 10px;
  }
}

.header-title {
  border-bottom: 1px solid $border-color;
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
}

.jodit-react-container span {
  color: #fff;
}
