/*#region ============== font ========== */
$fo-5: 5px;
$fo-4: 4px;
$fo-10: 10px;
$fo-11: 11px;
$fo-12: 12px;
$fo-13: 13px;
$fo-14: 14px;
$fo-15: 15px;
$fo-16: 16px;
$fo-18: 18px;
$fo-19: 19px;
$fo-20: 20px;
$fo-22: 22px;
$fo-24: 24px;
$fo-25: 25px;
$fo-32: 32px;

$weight-100: 100;
$weight-200: 200;
$weight-300: 300;
$weight-400: 300;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
/*#endregion ========== font ========== */
/*#region ============ color ========== */
$black: #000000;
$white: #ffffff;

$bg-color: #fafafa;
$text-color: #3c3b37;
$border-color: #dcdcdc;
//$border-color: #E2E7ED;
$bg-search: rgba(226, 237, 238, 0.3);
$pink-1: #f2cecd;
$purple: #7e0d9f;
$error: #ff6f59;

$navy-blue-1: #022834;
$navy-blue-2: #032e3b;
$navy-blue-3: #1f4550;

$deep-orange-1: #f38c09;
$deep-orange-2: #f5a53f;
$deep-orange-3: #f7b25b;
$deep-orange-4: #fce5c8;
$deep-orange-5: #fdf2e3;

$yellow-1: #fac600;
$yellow-2: #fbd955;
$yellow-3: #fce58d;
$yellow-4: #fdf2c6;
$yellow-5: #fef8e2;
$yellow-6: #ffd43f;

$teal-1: #006861;
$teal-2: #026567;
$teal-3: #1e7677;
$teal-4: #3a8788;
$teal-5: #e2edee;

$blue-1: #1cbbe8;
$blue-2: #38c3eb;
$blue-3: #55ccee;
$blue-4: #b3e8f7;
$blue-5: #d9f3fb;
$blue-6: #e4fcff;
$blue-7: #14ebff;

$red-1: #c72622;
$red-2: #e02b27;
$red-3: #f2cecd;
$red-4: #f9e8e7;

$grey-1: #acacac;
$grey-2: #c8cdd2;
$grey-3: #8e8e8e;
$grey-4: #f2f4f7;
$grey-5: #cacaca;
$grey-6: #e2e7ed;
$grey-7: #707070;
$grey-8: #212121;

/*#endregion ========= color ========== */

//border-radius
$radius-0: 0;
$radius-1: 4px;
$radius-2: 8px;
$radius-04: 0.4rem;
$radius-44: 44px;
$radius: 14px;
$radius-100: 100px;

//breakpoint
$breakpoint-mobile: 'only screen and (max-width : 575px)';
$breakpoint-tablet: 'only screen and (max-width: 767px)';
$breakpoint-l-desktop: 'only screen and (max-width: 992px)';
$breakpoint-xl-desktop: 'only screen and (max-width: 1200px)';

$breakpoint-min-ultra-screen: 'only screen and (min-width: 1200px)';
$breakpoint-min-xl-desktop: 'only screen and (min-width: 1024px)';
$breakpoint-min-l-desktop: 'only screen and (min-width: 992px)';
$breakpoint-min-l-tablet: 'only screen and (min-width: 768px)';
