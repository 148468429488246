@import './variables';

.btn {
  border-radius: $radius-2;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;

  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
}

.btn-success {
  color: #fff;
  background-color: $teal-1;
  border-color: $teal-1;

  &:hover {
    color: #fff;
    background-color: $teal-3;
    border-color: $teal-3;
  }

  &:disabled {
    background-color: $teal-3;
    border-color: $teal-3;
  }
}

.btn-info {
  color: #fff;
  background-color: $blue-1;
  border-color: $blue-1;

  &:hover {
    color: #fff;
    background-color: $blue-2;
    border-color: $blue-2;
  }

  &:disabled {
    background-color: $blue-2;
    border-color: $blue-2;
  }
}