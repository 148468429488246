@import './variables';

.form-control {
  padding: 0.35rem 0.75rem;
  border-radius: $radius-2;

  &:focus {
    box-shadow: none;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  height: 200px;
  resize: none;
}

.form-select {
  padding: 0.35rem 0.75rem;
  border-radius: $radius-2;

  &:focus {
    box-shadow: none;
  }
}
