@font-face {
  font-family: 'IRANSansWeb';
  src: url('../fonts/IRANSansWeb.eot') format('eot'),
    url('../fonts/IRANSansWeb.woff') format('woff'),
    url('../fonts/IRANSansWeb.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@for $i from 8 through 32 {
  .font-#{$i} {
    font-size: #{$i}px;
  }
}
